
import gql from "graphql-tag";

export const GET_MANAGEMENT_VOLUMES = gql`
  query GET_MANAGEMENT_VOLUMES(
    $page: Int
    $itemsPerPage: Int    
		$orderBy: VolumeOrderBy
		$direction: Direction
		$search: ShortString
		$diskId: UUID
		$diskStatus: [DiskHardwareStatusEnum!]
		$diskType: [BlockDeviceTypeEnum!]
		$status: [VolumeStatusEnum!]
  ){
	  management {
	    volumes(
	    	page: $page,
	    	itemsPerPage: $itemsPerPage,
	    	orderBy: $orderBy,
				direction: $direction,
				search: $search,
				diskId: $diskId,
				diskStatus: $diskStatus,
				diskType: $diskType,
				status: $status,
	    ) {
	      pageInfo {
    			count
    			itemsPerPage
    			maxItemsPerPage
    			currentPage
          numPages
	      }
	      results {
	        id
	        name
	        created
	        status
	        podDisplayName
	        device {
	          id
	          location
	          isAlive
	          network{
	            location
	            id
	            organization{
	              id
	              slug
	              name
	            }
	          }
	        }
	        disk {
	        	name
	        	parentDiskName
						partNumber
	          linuxId
	          hardwareStatus
	          bytesTotal
	          bytesUsed
	          id
	        }
	        task{
	        	id
	        }
	        pod {
	          id
	          status
	          statusDetail
	          currentVersion {
	            recipe {
	              name
	            }
	          }
	        }
	        sizeKilobytes
	        actions {
	          action
	          enabled
	          disabledReason
	        }		        
	      }
	    }
		}
	}
`;