
import gql from "graphql-tag";

export const GET_MANAGEMENT_PORTSCANS = gql`
  query GET_MANAGEMENT_PORTSCANS(
    $page: Int,
		$itemsPerPage: Int,
		$organizationSlug: ShortString,
		$networkId: UUID,
		$deviceId: String,
		$projectId: UUID,
		$hostId: UUID,
		$orderBy: PortScanOrderBy,
		$direction: Direction,
		$search: ShortString,
		$fromDate: DateTime,
		$toDate: DateTime,
		$status: [TaskStatusEnum!],
		$hostIsUp: Boolean, 
		$hasResult: Boolean,

		$askForPagination: Boolean!,
  ){    
   	management {
	    portScans(
		    page: $page,
				itemsPerPage: $itemsPerPage,
				organizationSlug: $organizationSlug,
				networkId: $networkId,
				deviceId: $deviceId,
				projectId: $projectId,
	  		hostId: $hostId, 
				orderBy: $orderBy,
				direction: $direction,
				search: $search,
				fromDate: $fromDate,
				toDate: $toDate,
				status: $status,
				hostIsUp: $hostIsUp, 
				hasResult: $hasResult
	    ) {
    		pageInfo @include(if: $askForPagination){
    			count
    			itemsPerPage
    			maxItemsPerPage
    			currentPage
    			numPages
    		}
	      results {
	        id
	        scheduledTime
	        duration
	        hostIsUp
	        rawDeviceId
	        task{
	        	errorMessage
	        }
        	network{
        		id
        		location
        	}
      		organization{
      			id
      			slug
      			name
      		}
	        fileName
	        fileSize
	        vlan {
	          id
	          name
	        }
	        host {
	          id
	          displayName
	        }
	        requestedBy { 
	        	firstname
	        	lastname
	        }
	        portCount
	        device {
	          id
	          isAlive
	          deviceId
	          isRebooting
	          location
	          actions {
	            action
	            enabled
	            disabledReason
	          }
	        }
	        task{
	        	id
		        startTime
		        status	        	
	        }
	        osDetection
	        tcp
	        udp	        
	        hostIpAddr
	        hostMacAddr
	        originalVlanName
	        result {
		        tcpPorts {
		          protocol
		          port
		          service
		          product
		          version
		          state
		        }
		        udpPorts {
		          protocol
		          port
		          service
		          product
		          version
		          state
		        }
		        osDetection {
		          name
		          accuracy
		          osFamily
		          osGen
		          cpe
		          type
		          vendor
		        }
		      }
	      }
	    }
	  }
	}
`;
