import React from 'react';
import "babel-polyfill";
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import Keycloak from 'keycloak-js';
import _ from 'lodash';

import { ApolloProvider, ApolloClient, ApolloLink, HttpLink, Observable, InMemoryCache, defaultDataIdFromObject } from '@apollo/client'
import { onError } from "@apollo/client/link/error";

import en from 'i18n/messages/en';
import fr from 'i18n/messages/fr';

import { registerLocale } from  "react-datepicker";
import {fr as fr_fns} from 'date-fns/locale';

import { ErrorToken } from 'components/ErrorToken';
import UpdateToken from 'components/UpdateToken';


import store from 'tools/SimpleStore.js';
import { customFetch } from 'tools/utils.js';

import "react-datepicker/dist/react-datepicker.css";
import 'styles/semantic/dist/semantic.css';

import BrowserRouter from 'routes/BrowserRouter';
//import registerServiceWorker from './registerServiceWorker';

function browserTest(){
  // Opera 8.0+
  const isOpera = (!!_.get(window,"opr.addons")) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

  // Firefox 1.0+
  const isFirefox = typeof InstallTrigger !== 'undefined';

  // Safari 3.0+ "[object HTMLElementConstructor]" 
  const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof _.get(window,"safari") !== 'undefined' && _.get(window,"safari").pushNotification));

  // Internet Explorer 6-11
  const isIE = /*@cc_on!@*/false || !!document.documentMode;

  // Edge 20+
  const isEdge = !isIE && !!window.StyleMedia;

  // Chrome 1 - 71
  const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

  // Blink engine detection
  const isBlink = (isChrome || isOpera) && !!window.CSS;

  return {
    isOpera: isOpera,
    isFirefox: isFirefox,
    isSafari: isSafari,
    isIE: isIE,
    isEdge: isEdge,
    isChrome: isChrome,
    isBlink: isBlink,
  }
}

let data = {en, fr};

const language = store('locale') || (navigator.languages && navigator.languages[0]) ||
                     navigator.language ||
                     navigator.userLanguage;


// Split locales with a region code
const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
store('locale', languageWithoutRegionCode);

// Try full locale, try locale without region code, fallback to 'en'
const messages = data[languageWithoutRegionCode] || data[language] || data.en;
const locale = (data[languageWithoutRegionCode] && languageWithoutRegionCode) || ( data[language] && language ) || "en";

registerLocale('fr', fr_fns)
// Init Apollo client
const cache = new InMemoryCache({
  dataIdFromObject: object => {
    return defaultDataIdFromObject(object);
  }
});

const consoleLink = new ApolloLink((operation, forward) => {
  //console.log(`starting request for ${operation.operationName}`);
  //UpdateToken.updateToken()
  return forward(operation).map((data) => {
    //console.log(`ending request for ${operation.operationName}`);
    return data;
  })
})

window.browserTest = browserTest();

window.simulateFetchErrors = {
  rate: 0, // 0 to 100
  contains: null, // Trigger if the string match, case insensitive
}

const httpLink = new HttpLink({
  uri:  `https://api.${window.sentinelc_domain}/graphql`,
  credentials: 'include',
  fetch: customFetch,
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.log(graphQLErrors, networkError);
  }

  if (networkError) console.log(`[Network error] ${networkError}`, networkError.statusCode );
});

const request = async (operation) => {
  let headers = {
    "accept-language": languageWithoutRegionCode,
  };

  if (window.keycloak.token) {
    headers.authorization= "Bearer " + window.keycloak.token;
  }

  operation.setContext({
    headers
  });
};

const requestLink =new ApolloLink((operation, forward) =>
  new Observable(observer => {
    let handle;
    if (!window.keycloak.authenticated && operation.operationName.indexOf("onboarding") < 0) {
      UpdateToken.updateToken()
    }
    Promise.resolve(operation)
      .then(oper => request(oper))
      .then(() => {
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
      .catch(observer.error.bind(observer));

    return () => {
      if (handle) handle.unsubscribe();
    };
  })
);

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([errorLink, requestLink, consoleLink, httpLink]),
});

const container = document.getElementById('root');
const root = createRoot(container);

const defaultRichTextElements={
  b: chunks => <b>{chunks}</b>,
  p: chunks => <p>{chunks}</p>,
  strong: chunks => <strong>{chunks}</strong>,
  statusinlinenegative: chunks => <strong className="status-inline negative 9">{chunks}</strong>,
  sublabel: chunks =>  <span className="sublabel">{chunks}</span>,
  exergue: chunks =>  <span className="exergue">{chunks}</span>,
  ul: chunks => <ul>{chunks}</ul>,
  li: chunks => <li>{chunks}</li>,
  em: chunks => <em>{chunks}</em>,
  small: chunks => <small>{chunks}</small>,
  span: chunks => <span>{chunks}</span>,
  h1: chunks => <h1>{chunks}</h1>,
  linebreak: <br/>,
  a: chunks => <a href={_.get(this.props,"values.href")}>{chunks}</a>,
  h1ash3: chunks => <h1 className="as-h3 label-block">{chunks}</h1>,
}


// Keycloak authentication
window.keycloak = new Keycloak({
  realm: "sentinelc",
  url: `https://accounts.${window.sentinelc_domain}`,
  clientId: "sentinelc-front",
});
window.keycloak.init({onLoad: 'check-sso', locale: locale}).then(function(authenticated) {

  // Polyfill intl

  if (!window.intl) {
    import("intl").then((intl) => {

      import('intl/locale-data/jsonp/en.js'); // eslint-disable-line no-unused-expressions
      import('intl/locale-data/jsonp/fr.js'); // eslint-disable-line no-unused-expressions

      root.render(
        <IntlProvider locale={locale} key={locale} messages={messages} defaultRichTextElements={defaultRichTextElements}>
          <ApolloProvider client={client}>
            <ErrorToken>
              <BrowserRouter />
            </ErrorToken>
          </ApolloProvider>
        </IntlProvider>
      );
    });
  } else { // Intl supported by browser
    root.render(
      <IntlProvider locale={locale} key={locale} messages={messages} defaultRichTextElements={defaultRichTextElements}>
        <ApolloProvider client={client}>
          <ErrorToken>
            <BrowserRouter />
          </ErrorToken>
        </ApolloProvider>
      </IntlProvider>
    );
  }
})


//registerServiceWorker();
