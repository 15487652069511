import en from 'i18n/messages/en';
import fr from 'i18n/messages/fr';


export function testForMissingTranslation(){

	var lang = {};

	for(var key in fr){
		lang[key] = {lang:"fr", content: fr[key]};
	}

	for(var keyEn in en){
		if (!lang[keyEn])
		lang[keyEn] = {lang:"en", content: en[keyEn]};
		else
			delete lang[keyEn];
	}

	console.log("Translation available in only one of languages",lang)
}

export function customFetch(url, _options){

	if(!window.simulateFetchErrors){
		return window.fetch(url, _options)
	}

  let throwError = false;
  let draw = 0;
  let brokenBody = "{}";
  const rate = window.simulateFetchErrors.rate > 100 ? 100 : window.simulateFetchErrors.rate;
  if(
    window.simulateFetchErrors.rate > 0 
    && (
      (
        window.simulateFetchErrors.contains 
        &&
         _options.body.toLowerCase().indexOf(window.simulateFetchErrors.contains.toLowerCase()) >= 0
      )
      ||
      !window.simulateFetchErrors.contains
    )
  ){
    draw = Math.floor(Math.random() * 100);
    throwError = draw <= rate;
  } 

  if (throwError) {
    console.error(`Simulated error - Rate: ${rate}% ${window.simulateFetchErrors.contains ? ` - contains: "${window.simulateFetchErrors.contains}"` : ""}`);
    return window.fetch(url, Object.assign(_options, {body: brokenBody}));
  }
  return window.fetch(url, _options)
}