import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';

function PrimaryButtonBase (props){
  const {
    color,
    content,
    disabled,
    i18nId,
    intl,
    loading,
    primary,
    size,
    type,
    error,
    onClick,
    afterContent,
    ...rest
  } = props;

  const _content = (content || i18nId || afterContent) ? (
    <Fragment>
      {i18nId ? intl.formatMessage({id:i18nId}) : content}
      {afterContent}
    </Fragment>
  ) : null;

  const isPrimary = typeof primary !== "boolean" && typeof color !== "string";

  return (<Button
    color={color}
    content={(_content)}
    primary={isPrimary}
    loading={loading}
    type={type || "button"}
    disabled={loading || null}
    className={disabled ? "disabled activable" :""}
    size={size}
    onClick={typeof onClick === "function" ? onClick : null}
    {...rest}
  >
  </Button>
  );
}

export const PrimaryButton = injectIntl(PrimaryButtonBase)