import gql from "graphql-tag";

export const GET_MANAGEMENT_ORGANIZATIONS_LIGHT = gql`
  query GET_MANAGEMENT_ORGANIZATIONS_LIGHT(
    $page: Int,
    $itemsPerPage: Int,
    $severity: [IncidentSeverityFilter!]
    $search: ShortString,
    $orderBy: OrganizationOrderBy,
    $direction: Direction,
    $hasInventory: Boolean,

    $askForPagination: Boolean!,
  ){
    management{
      organizationsLight: organizations(
        page: $page,
        itemsPerPage: $itemsPerPage,
        severity: $severity,
        search: $search,
        orderBy: $orderBy,
        direction: $direction,
        hasInventory: $hasInventory,
      ){
        pageInfo @include(if: $askForPagination){
          count
          itemsPerPage
          maxItemsPerPage
          currentPage
          numPages
        }
        results{
          id
          name
          slug
          enableOperations
          contact{
            firstname 
            lastname
            email
            phonenumber
          }
        }         
      }
    }
  }`;

export const GET_MANAGEMENT_ORGANIZATIONS = gql`
  query GET_MANAGEMENT_ORGANIZATIONS(
    $page: Int,
    $itemsPerPage: Int,
    $severity: [IncidentSeverityFilter!]
    $search: ShortString,
    $orderBy: OrganizationOrderBy,
    $direction: Direction,
    $organizationSlugs: [ShortString!],
    $inventoryStatus: [InventoryStatus!],
    $hasInventory: Boolean,

    $askForPagination: Boolean!,
  ){
    management{
      organizations(
        page: $page,
        itemsPerPage: $itemsPerPage,
        severity: $severity,
        search: $search,
        orderBy: $orderBy,
        direction: $direction,
        organizationSlugs: $organizationSlugs,
        hasInventory: $hasInventory,
      ){
        pageInfo @include(if: $askForPagination){
          count
          itemsPerPage
          maxItemsPerPage
          currentPage
          numPages
        }
        results{
          id
          name
          tagline
          slug
          warning
          numId
          logo{
            default
            id
          }
          enableInventory
          enableOperations
          countDevices(inventoryStatus: $inventoryStatus)
          countNetworks
          countIncidents
          lastIncidentUpdate
          incidents(page:1, itemsPerPage:5, status: [OPEN]){
            pageInfo{
              count
              itemsPerPage
            }
            results{
              id
              summary
              severity
              created
            }
          }
        }         
      }
    }
  }`;

export const GET_MANAGEMENT_ORGANIZATION_NETWORKS = gql`
  query GET_MANAGEMENT_ORGANIZATION_NETWORKS(
    $organizationSlug: ShortString
  ){
    management{
      organization(organizationSlug: $organizationSlug) {
        id
        slug
        name
        contact{
          firstname 
          lastname
          email
          phonenumber
        }
        createdAt
        networks{
          id
          location
          useOrganizationContact
          contact{
            firstname
            lastname
            email
            phonenumber
          }
          vlans{
            id
            name
            accessPolicy
            description
            management
            passthrough
            securityProfile{
              id
            }
            subnet
            type
            vid
          }
          devices{
            id
            deviceId
            location
            isAlive
            countRadios
            operatingMode
            maxSsids
            countSsidsEnabled: countSsids(inStatus: [ONLINE, PARTIAL])
            countAllSsids: countSsids
            actions {
              action
              enabled
              disabledReason
            }     
            radios {
              macAddress
              configuredChannel{
                channel
                displayName
                availableTxPowers{
                  label
                  value
                  actual
                }
              }
              bands
              enabled
            }
          }
        }
      }
    }
  }`;

export const GET_MANAGEMENT_ORGANIZATION = gql`
  query GET_MANAGEMENT_ORGANIZATION(
    $organizationSlug: ShortString
  ){
    management{
      organization(organizationSlug: $organizationSlug) {
        id
        slug
        name
        enableInventory
        availableActions
        enableOperations
        contact{
          firstname 
          lastname
          email
          phonenumber
        }
        createdAt
        lastIncident:incidents(page:1, itemsPerPage:1, status: [OPEN]){ 
          results{
            id
            created
          } 
        }
        logo{
          default
          id
        } 
        countAllIncidents: countIncidents
        countOpenIncidents: countIncidents(status: OPEN)
        devices(page:1, itemsPerPage:100){ 
          pageInfo{ 
            count 
            itemsPerPage 
          } 
          results{ 
            id
            deviceId
            location
          }
        }
        networks{
          id
          location
          useOrganizationContact
          contact{
            firstname
            lastname
            email
            phonenumber
          }
          countPendingHosts: countHosts(unauthorized: true) 
          devices{
            id
            deviceId
            location
            isAlive
          }
          address{ 
            postalCode 
            address1 
            address2 
            city 
            countryCode 
            regionCode 
            latitude 
            longitude 
          } 
        }
      }
    }
  }`;

/*
      countDNSDomains: dns{
        statsByDomain(organizationSlug: $organizationSlug,itemsPerPage: 0, groupByPrivateSuffix: true){
          pageInfo{
            count
          }
        }
      }
*/

export const GET_MANAGEMENT_ORGANIZATION_STATS = gql`
  query GET_MANAGEMENT_ORGANIZATION(
    $organizationSlug: ShortString
  ){
    management{
      countIPEvents: packetLogs(organizationSlug: $organizationSlug,itemsPerPage: 0){
        pageInfo{
          count
        }
      }
      organization(organizationSlug: $organizationSlug) {
        id
        slug
        name
        countAllHosts: countHosts(byLastConnection: true, excludeOrganizationDevices: false)
        countOnlineHosts: countHosts(connectionStatus: ONLINE, excludeOrganizationDevices: false, byLastConnection: true)
        countOfflineHosts: countHosts(connectionStatus: OFFLINE, excludeOrganizationDevices: false, byLastConnection: true)

        countOnlineConnections: countConnections(isOnline: true)
        countOfflineConnections: countConnections(isOnline: false)

        countDevices
        countPacketCaptures(status: COMPLETED)
        countPortScans(status: COMPLETED)
        countOpenPorts
        countWifiSurveys
        countRogueSsids        

        countOPENIncidents: countIncidents(status: OPEN)
        countCLOSEDIncidents: countIncidents(status: CLOSED)
        countRESOLVEDIncidents: countIncidents(status: RESOLVED)

        countPENDINGTasks: countTasks(status: PENDING)
        countSTARTEDTasks: countTasks(status: STARTED)
        countFAILEDTasks: countTasks(status: FAILED)
        countCOMPLETEDTasks: countTasks(status: COMPLETED)
        countCANCELLEDTasks: countTasks(status: CANCELLED)
      }
    }
  }`;

export const GET_MANAGEMENT_ORGANIZATION_ADMINS = gql`
  query GET_MANAGEMENT_ORGANIZATION_ADMINS(
    $id: UUID,
  ){
    organization(id:$id) {
      contact{
        firstname 
        lastname
        email
        phonenumber
      }
      createdAt      
      users(itemsPerPage:20){
        pageInfo {
          count
          currentPage
          maxItemsPerPage
          itemsPerPage
          numPages
        }
        results{
          firstname
          lastname     
          user{
            email
            isAuditor
            isPrivileged  
          }
          createdAt
        }
      }
    }

  }`;

export const GET_MANAGEMENT_ORGANIZATION_DEVICES = gql`
  query GET_MANAGEMENT_ORGANIZATION_DEVICES(
    $organizationSlug: ShortString,
    $inventoryStatus: [InventoryStatus]
  ){
    management{
      deviceConfigurationTemplates {
        name
        operatingMode
        displayName
      }
      organization(organizationSlug:$organizationSlug) {
        contact{
          firstname 
          lastname
          email
          phonenumber
        }
        createdAt
        devices(page:1, itemsPerPage:100, inventoryStatus: $inventoryStatus){
          pageInfo{
            count
            itemsPerPage
          }
          results{
            id
            deviceId
            location
            isAlive
            countRadios
            countPorts
            operatingMode
            inventoryStatus
            usage
            configurationTemplate{
              name
              displayName
              operatingMode
            }
            usage
          }
        }
      }
    }
  }`;

export const GET_ORGANIZATIONS_BO = gql`
  query GET_ORGANIZATIONS_BO{
    timezoneList: __type(name:"TimezoneOptions") {
      name
      enumValues {
        name
        description
      }
    }
    viewer{
      id
      isPrivileged
      isAuditor
      hasInventoryAccess
      firstname
      lastname
      email
      organizationUsers{
        id
        organization{
          id
          slug
          name
          enableOperations
          countNewInventorydevices: countDevices(inventoryStatus: [NEW, RETURNED, DEFECTIVE])
        }
      }
    }
    management{
      securityProfiles{
        id
        name
        version
        description
        level
        ipListRules {
          list {
            name
          }
          action
        }
        isLatest
        isDefault
        isDeprecated
      }
      releaseChannels
      recipeCategories
      availableRepositories
      inventory: organization(hasInventory:true) {
        id
        slug
        name
        enableOperations
      }
      statsOpenIncidents: incidents(
        status: [OPEN]
        page: 1,
        itemsPerPage: 0,
      ){
        pageInfo{
          count
        }
      }
    }
  }`;