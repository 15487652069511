import gql from "graphql-tag";

export const GET_PACKET_LOGS = gql`
  query GET_PACKET_LOGS(
    $page: Int
    $itemsPerPage: Int
    $search: ShortString
    $fromDate: DateTime
    $toDate: DateTime
    $hostId: UUID
    $packetDirections: [PacketDirection]
    $actions: [IPListAction]
    $orderBy: PacketLogOrderBy
    $direction: Direction
    $networkId: UUID,
    $organizationSlug: ShortString,
    $ipAddress: ID
  ){
    management {
      packetLogs(
        page: $page
        itemsPerPage: $itemsPerPage
        fromDate: $fromDate
        toDate: $toDate
        hostId: $hostId
        search: $search
        packetDirections: $packetDirections
        actions: $actions
        orderBy: $orderBy
        direction: $direction
        networkId: $networkId,
        organizationSlug: $organizationSlug,
        ipAddress: $ipAddress,
      ) {
        pageInfo {
          count
          currentPage
          maxItemsPerPage
          itemsPerPage
          numPages
        }
        results {
          id
          network {
            id,
            location,
            organization{
              id
              slug
              name
            }
          }
          host {
            id, 
            displayName, 
            macAddr
          }
          connection {
            id
            isOnline, 
            startTime, 
            badConnection,
            endTime,
            band,
            ssid {
              name,
            },
            media
            context {
              deviceLocation
              deviceId
              isBlocked
              portLabel
              vlanAccessStatus
              vlanAccessPolicy
              vlanType
              vlanName
              ip
              hostType
            }
          }
          toTime
          fromTime
          direction
          list {name}
          action
          sourceIp
          sourcePort
          destIp
          destPort
          proto
          originPacketCount
          replyPacketCount
          originKilobytes
          replyKilobytes
        }
      }
    }
  }
`;

export const GET_IPADDRESS_STATS = gql`
  query GET_IPADDRESS_STATS(
    $ipAddressId: ID!
    $packetLogId: ID!
    $hostId: UUID!
    $networkId: UUID
    $requestTimeTo: DateTime
  ){
    management {
      packetLog(packetLogId:$packetLogId) {
        id
        city
        countryCode
        asNumber
        asOrganization
        ipDetails {
          value
          firstSeen
          firstSeenByHost(hostId:$hostId)
          statsByNetwork {
            network { 
              location,
              organization{
                name
                slug
              }
            }
            countEvents
            firstSeen
            lastSeen
            latestDnsQuery {
              query {
                qname {
                  name
                  privateSuffix {
                    name
                  }
                }
              }
            }
          }
        }
      }
      packetLogs(
        hostId: $hostId,
        networkId: $networkId
        ipAddress: $ipAddressId
        itemsPerPage: 0
      ) {
        pageInfo {
          count
        }
      }
      dns {
        statsByHost(
          hostId: $hostId
          ipAddress: $ipAddressId
          groupByPrivateSuffix: false
          requestTimeTo:$requestTimeTo
        ) {
          pageInfo{
            count
            itemsPerPage
          }
          results {
            domain {
              name
              privateSuffix{
                name
              }
            }
          }
        }
      }
    }
  }
`;