import gql from "graphql-tag";

//networkLocation: reportLine(key:"NETWORK_LOCATION")

export const GET_MANAGEMENT_MONITORINGEVENTS = gql`
  query GET_MANAGEMENT_MONITORINGEVENTS(
    $page: Int
		$itemsPerPage: Int
		$localTimeFrom: DateTime!
		$localTimeTo: DateTime!
		$deviceIds: [String]
		$firmwareInstalled: String
		$organizationId: UUID
		$networkId: UUID
		$eventType: [MonitoringEventTypeEnum]
		$search: ShortString
		$direction: Direction
		$orderBy: MonitoringEventOrderBy

		$askForPagination: Boolean!,
  ){    
  	management{
	    monitoringEvents(
				page: $page
				itemsPerPage: $itemsPerPage
				localTimeFrom: $localTimeFrom
				localTimeTo: $localTimeTo
				deviceIds: $deviceIds
				firmwareInstalled: $firmwareInstalled
				organizationId: $organizationId
				networkId: $networkId
				eventType: $eventType
				search: $search
				direction: $direction
				orderBy: $orderBy
    	){
    		pageInfo @include(if: $askForPagination){
    			count
    			itemsPerPage
    			maxItemsPerPage
    			currentPage
          numPages
    		}
	      userError{
	        code
	        message
	      }
    		results{
					firmware
					id
					localTime
					metadata
					recordedTime
					type
          organization{
            id
            name
            slug
          }
          network{
            id
            location
          }
          device{
          	id
          	deviceId
          	location
          	isAlive
          	firmwareStatus
          	location
	          currentFirmware{
	            version
	          }
          }
    		}
	    }
  	}
  }`;